import React, { Component } from "react";
import "zingchart/es6";
import ZingChart from "zingchart-react"; 

class Chart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        type: 'bar',
        theme: 'classic',
        globals: {
          fontFamily: 'Helvetica',
        },
        backgroundColor: 'white',
        title: {
          text: 'Alexa: Top Software Sites - Traffic from Search, 7/23/19',
          backgroundColor: 'white',
          color: '#606060',
        },
        subtitle: {
          text: 'Click the columns to view top search keywords. Source: alexa.com.',
          color: '#606060',
        },
        plot: {
          tooltip: {
            visible: false,
          },
          valueBox: {
            text: '%v%',
            color: '#606060',
            textDecoration: 'underline',
          },
          animation: {
            effect: 'ANIMATION_EXPAND_HORIZONTAL',
          },
          cursor: 'hand',
          dataBrowser: [
            "<span style='font-weight:bold;color:#1976d2;'>Mathworks.com</span>",
            "<span style='font-weight:bold;color:#424242;'>Ansys.com</span>",
            "<span style='font-weight:bold;color:#388e3c;'>Arenasimulation.com</span>",
            "<span style='font-weight:bold;color:#ffa000;'>Omnetpp.org</span>",
            "<span style='font-weight:bold;color:#7b1fa2;'>Promodel.com</span>",
          ],
          hoverState: {
            border: '2px solid #ffff00',
          },
          rules: [{
              backgroundColor: '#1976d2',
              rule: '%i==0',
            },
            {
              backgroundColor: '#424242',
              rule: '%i==1',
            },
            {
              backgroundColor: '#388e3c',
              rule: '%i==2',
            },
            {
              backgroundColor: '#ffa000',
              rule: '%i==3',
            },
            {
              backgroundColor: '#7b1fa2',
              rule: '%i==4',
            },
            {
              backgroundColor: '#c2185b',
              rule: '%i==5',
            },
          ],
        },
        scaleX: {
          values: [
            'Mathworks.com',
            'Ansys.com',
            'Arenasimulation.com',
            'Omnetpp.org',
            'Promodel.com',
          ],
          guide: {
            visible: false,
          },
          item: {
            color: '#606060',
          },
          lineColor: '#C0D0E0',
          lineWidth: '1px',
          tick: {
            lineColor: '#C0D0E0',
            lineWidth: '1px',
          },
        },
        scaleY: {
          guide: {
            lineStyle: 'solid',
          },
          item: {
            color: '#606060',
          },
          lineColor: 'none',
          tick: {
            lineColor: 'none',
          },
        },
        crosshairX: {
          lineColor: 'none',
          lineWidth: '0px',
          marker: {
            visible: false,
          },
          plotLabel: {
            text: '%data-browser: %v% of total',
            alpha: 0.9,
            backgroundColor: 'white',
            borderRadius: '4px',
            borderWidth: '3px',
            callout: true,
            calloutPosition: 'bottom',
            color: '#606060',
            fontSize: '12px',
            multiple: true,
            offsetY: '-20px',
            padding: '8px',
            placement: 'node-top',
            rules: [{
                borderColor: '#1976d2',
                rule: '%i==0',
              },
              {
                borderColor: '#424242',
                rule: '%i==1',
              },
              {
                borderColor: '#388e3c',
                rule: '%i==2',
              },
              {
                borderColor: '#ffa000',
                rule: '%i==3',
              },
              {
                borderColor: '#7b1fa2',
                rule: '%i==4',
              },
              {
                borderColor: '#c2185b',
                rule: '%i==5',
              },
            ],
            shadow: false,
          },
          scaleLabel: {
            visible: false,
          },
        },
        series: [{
          values: [59.9, 41.3, 51.2, 48.4, 23.5],
        }, ],
      }
    };
    this.chart = React.createRef();
  }
  render() {
    return (
      <div className="charboxx">
        <ZingChart ref={this.chart} data={this.state.config} />
      </div>
    );
  }
}

export default Chart;
