import { Link} from 'react-router-dom';
import React,{useEffect,useState}  from 'react';
import {getpagecontent} from '../service/Makerequest';

function Account_footer (){
  const [companyinfodata,settData]=useState({'payload':'','body':'','loader':true});		
	const callfootercontent=async()=>{			
	 	const data=await getpagecontent('company/settings');	
		if(data){		
	 		settData({'payload':data.payload,'body':data.payload.data,'loader':false}) 
		}	
	}
	useEffect(()=>{					
	 	callfootercontent();			
	},[]);
return(
<>
<div className="footer hidden">
	<div className="copy">
		<div className="container">
			<div className="row">
				<div className="col-lg-12 col-md-12 col-sm-12 copyRES">
					<div className="copyInner">
						<p>&copy; Copyright {companyinfodata.body.company_start}  <Link to="" target="_blank">Cryptocoin</Link>. All rights reserved.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>
</>
)
}
export default Account_footer ;