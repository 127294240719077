import React  from 'react';
import { Routes, Route} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Homelayout from '../components/Home_layout';
import Home from '../components/Home';
import Contact from '../components/Contact';
import Faq from '../components/Faq';
import Privacypolicy from '../components/Privacy_policy';
///all inner pages dynamic in this page..
import Serviceinnerpages from '../components/service_inner_pages';
import Blog from '../components/Blog';
import Exchangerates from '../components/Exchange_rates';
import Invoiceform from '../components/Invoiceform';
import Errorpage from '../components/Error';
import useToken from './useToken';



import Login from '../account/Login';
import Logout from '../account/Logout';
import Register from '../account/Register';
import Forgotpassword from '../account/Forgot_password';
import Resetpassword from '../account/Reset_password';
///dashboard
import Accountlayout from '../account/Account_layout';
import Dashboard from '../account/Dashboard';
import ViewProfile from '../account/ViewProfile';
import ChangePassword from '../account/ChangePassword';
import Editprofile from '../account/Editprofile';
import Paymentlink from '../account/Payment_link';
import Paymentlinkfinal from '../account/Payment_link_final';
import Transaction from '../account/Transaction';
import Invoice from '../account/Invoices';




const Public = () => {
  const { setToken } = useToken();

return(
<>

<Routes>
<Route path="/" element={<Homelayout/>} >
<Route Index path="/" element={<Home />} />
<Route path="/Contact" element={<Contact />} />


<Route path="/crypto-payments-for-psps" element={<Serviceinnerpages />} />
<Route path="/crypto-payment-for-ecommerce" element={<Serviceinnerpages />} />
<Route path="/crypto-payment-by-invoicing" element={<Serviceinnerpages />} />
<Route path="/crypto-payment-for-psps" element={<Serviceinnerpages />} />
<Route path="/crypto-payment-for-remittance" element={<Serviceinnerpages />} />
<Route path="/crypto-trading-for-digital-wallet" element={<Serviceinnerpages />} />

<Route  path="/about-us" element={<Serviceinnerpages />} />
<Route  path="/newsroom" element={<Serviceinnerpages />} />
<Route  path="/terms-and-conditions" element={<Serviceinnerpages />} />
<Route  path="/careers" element={<Serviceinnerpages />} />
<Route  path="/Faq" element={<Faq />} />

<Route  path="/crypto-ownership-data" element={<Serviceinnerpages />} />

<Route  path="/Blog" element={<Blog />} />

<Route  path="/whitepaper" element={<Serviceinnerpages />} />
<Route  path="/api-documentation" element={<Serviceinnerpages />} />


<Route  path="/Exchange_rates" element={<Exchangerates />} />




<Route  path="/Privacy_policy" element={<Privacypolicy />} />




<Route  path="/Login" element={<Login setToken={setToken}/>} />
<Route  path="/Register" element={<Register />} />
<Route  path="/Forgot_password" element={<Forgotpassword />} />
<Route  path="/reset/password" element={<Resetpassword />} />
<Route  path="/logout" element={<Logout/>} />
<Route path="/invoice/:slug" element={<Invoiceform />} />
</Route>



<Route path="/client" element={<Accountlayout />} >
  <Route path="/client/" element={<Dashboard />} /> 
  <Route path="/client/viewProfile" element={<ViewProfile />} />
  <Route path="/client/changePassword" element={<ChangePassword />} />
  <Route path="/client/editprofile" element={<Editprofile />} />
  <Route path="/client/payment-link" element={<Paymentlink />} />
  <Route path="/client/payment-link-final" element={<Paymentlinkfinal />} />
  <Route path="/client/transaction" element={<Transaction />} />
  <Route path="/client/invoice" element={<Invoice />} />
  
  
  


</Route>
<Route path="*" element={<Errorpage/>}/> 
</Routes>
</>
)}
export default Public;