
import React,{useEffect,useState} from 'react';
import {getpagecontent} from '../service/Makerequest';
import { Dna } from 'react-loader-spinner'
import moment from "moment";
function Transaction() {
  const [transactiondata,setData]=useState({'payload':'','body':'','loader':true});		
	const callpagecontent=async()=>{	
    const tokenString = localStorage.getItem("token");                
    const token = JSON.parse(tokenString);  		
		const data=await getpagecontent('payment/transaction',token);				
		setData({'payload':data.payload,'body':data.payload.data,'loader':false}) 	
	}
	useEffect(()=>{					
		callpagecontent();			
	},[]);
  return (
   <>
  <Dna
			visible={transactiondata.loader}
			height="80"
			width="80"
			ariaLabel="dna-loading"
			wrapperStyle={{'margin':'0 50%'}}
			wrapperClass="dna-wrapper"
		/>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="Paymentactivity">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12"><div class="Payment_tittl">Payments Details</div></div>
        </div>
        <div class="row">
          <div class="chart">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <table class="table">
                <thead class="table-dark">
                  <tr>
                    <th scope="col">Reference</th>
                    <th scope="col">Date and Time</th>
                    <th scope="col">Status</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Currency Mode</th>
                  </tr>
                </thead>
                {transactiondata.body.length > 0 && ( 
                <tbody>           
                  {transactiondata.body.map((invoice,i) => (
                    <tr>                        
                      <td>{invoice.payment_reference}</td>
                      <td>{moment(invoice.created_at).format("MMMM Do, YYYY HH:MM:SS")}{" "}</td>                                            
                      <td><span className="status sactive">{invoice.status}</span></td>
                      <td>{invoice.payment_receive_amount}</td>
                      <td>{invoice.crypto_currency}</td>
                    </tr>
                  ))}
                </tbody>
                )}
              </table>
            </div>			
          </div>
        </div>
      </div>
    </div>
  </div>
</>
  );
}

export default Transaction;
