
export const getpagecontent=async(page,token="dd")=>{  
	try{  
	///online   
	const res=await fetch('https://zeligzagency.com:3000/api/v1/'+page,{
	///local
	//const res=await fetch('http://localhost:3001/api/v1/'+page,{
		headers:{"Content-Type":"application/json","authorization":token}
	}); 
	 
	const data=await res.json();		   
	return data;   
}catch(e){
	return false
}   
}

export const postdataa=async(path,data,token="dd")=>{	
	////online
	const res=await fetch('https://zeligzagency.com:3000/api/v1/'+path,{
	///local
	//const res=await fetch('http://localhost:3001/api/v1/'+path,{
		method:"POST",
		headers:{"Content-Type":"application/json","authorization":token},
		body:JSON.stringify(data)
	});
	const dat=await res.json();
	return dat;
}
///online
export const serverbasepath='https://zeligzagency.com:2207'///basepath of back-end server(admin)
export const frontbasepath='https://zeligzagency.com'///basepath of react js

//local
//export const serverbasepath='http://localhost:2207'///basepath of back-end server(admin)
//export const frontbasepath='http://localhost:3000'///basepath of react js