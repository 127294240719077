import React, { useEffect, useState } from "react";
import { postdataa,frontbasepath } from "../service/Makerequest";
import {useNavigate} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Paymentlink() {
        const history = useNavigate();
        const [show, setShow] = useState(false);
        const handleClose = () => setShow(false);
        const handleShow = (e) =>{ 
                e.preventDefault();
                setShow(true);

        }

        const [userinfo,setuserinfo]=useState({username:"",token:""});
        const callprofile = async () => {
                const tokenString = localStorage.getItem("token");
                const username = localStorage.getItem("username");
                const token = JSON.parse(tokenString);                
                setuserinfo({
                        username:username,
                        token:token                        
                });
        };
        useEffect(() => {
        callprofile();
        }, []);


        const [invoicedt, setinvoicedata] = useState({ merchant: "", testnet: false,payername:"",payeremail:"",currency:"",amount:"",description:"",password:"",base_url:""});
        let name, value;

        const handelInputs = (e) => {
                name = e.target.name;
                value = e.target.value;
                setinvoicedata({ ...invoicedt, [name]: value });
        };
        const postData = async (e) => {
                e.preventDefault();
                handleClose();  
                invoicedt.base_url= frontbasepath;                                     
                const respost=await postdataa('payment/link',invoicedt,userinfo.token);                  
                if (respost.status >205 || !respost) {
                        window.alert(respost.msg);
                } else {      
                        setinvoicedata({merchant: "", testnet: false,payername:"",payeremail:"",currency:"",amount:"",description:"",password:"",base_url:""})                         
                        localStorage.setItem('invoiceid',respost.payload.invoiceid); 
                        window.alert(respost.msg);
                        history('/client/payment-link-final');           
                }                 
              };
        return (
        <>
        <div className="paymentrequestmain">
                <div className=" col-lg-12 col-md-12 col-sm-12">
                <div className="paymentittle">
                <h2>Make a payment request</h2>
                <p>
                Send a payment request via Email and receive payment in crypto.
                </p>
                </div>
                </div>
                <div className=" col-lg-6 col-md-12 col-sm-12">
                <div className="commonform">
                <form method="post">
                <div className=" row">
                        <div className=" col-lg-12 col-md-12 col-sm-12">
                        <div className=" paymentop">
                        <div className="form-group">
                        <label className="control-label">
                                Which merchants should receive the payment ?{" "}
                                <i className="redd">*</i>
                        </label>
                        <select className="form-control" name="merchant" onChange={handelInputs}>
                                <optgroup label="Merchants">
                                <option selected={invoicedt.merchant === ""} value="">Select merchant</option>
                                <option checked={invoicedt.merchant === userinfo.token} value={userinfo.token}>{userinfo.username}</option>
                                </optgroup>
                        </select>
                        </div>

                        <label class="checbox">
                        <input type="checkbox" name="testnet" value="1" onChange={handelInputs} checked={invoicedt.testnet === "1"}/>
                        <span class="checkmark"></span> Activate sandbox mode to
                        test the invoicing function using test bitcoin (testBTC).
                        </label>
                        </div>
                        </div>

                        <div className=" col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                        <label className="control-label">
                        Payer's name (Individual or company)
                        </label>
                        <input
                        name="payername"
                        value={invoicedt.payername}
                        className="form-control"
                        placeholder="Payer name"
                        type="text" onChange={handelInputs}
                        />
                        </div>
                        </div>

                        <div className=" col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                        <label className="control-label">
                        Payer's email <i className="redd">*</i>
                        </label>
                        <input
                        name="payeremail"
                        value={invoicedt.payeremail}
                        className="form-control"
                        placeholder="payer email"
                        type="text" onChange={handelInputs}
                        />
                        </div>
                        </div>

                        <div className=" col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                        <label className="control-label">
                        Currency *<i>*</i>
                        </label>
                        <select className="form-control" name="currency" onChange={handelInputs}>
                        <option selected={invoicedt.currency === ""} value=""> Select currency</option> 
                        <option selected={invoicedt.currency === "USD"} value="USD"> USD</option>                        
                        </select>
                        </div>
                        </div>

                        <div className=" col-lg-6 col-md-12 col-sm-12">
                        <div className="form-group">
                        <label className="control-label"> Invoice Amount *</label>
                        <input
                        name="amount"
                        className="form-control"
                        placeholder="Amount"
                        type="number"
                        value={invoicedt.amount}
                        step=".1" onChange={handelInputs}
                        />
                        </div>
                        </div>

                        <div className=" col-lg-12 col-md-12 col-sm-12">
                        <div className="form-group">
                        <label className="control-label">
                        {" "}
                        Description <i className="redd">*</i>
                        </label>
                        <textarea className="form-control" name="description" onChange={handelInputs} value={invoicedt.description}></textarea>
                        </div>
                        </div>

                        <div className="formroup textCenter">
                        <button className="btn btnTheme" variant="primary" onClick={handleShow}>
                        Create a payment request
                        </button>
                        </div>
                </div>
                </form>
                </div>
                </div>
        </div>


      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Password Authentication </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                
        <div className="form-group"><label className="control-label">Password</label>
        <input value={invoicedt.password} name="password" onChange={handelInputs} className="form-control" placeholder="Password" type="password" />
        </div>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={postData}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

        </>
        );
}
export default Paymentlink;
