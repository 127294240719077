import React, { useEffect, useState } from "react";
import {getpagecontent } from "../service/Makerequest";
import moment from "moment";

import Chart from './chartjs/Chart';



function Dashboard (){  
    const [transaction,setData]=useState({today:"",sdayback:"",thdayback:"",payment_details:""});	
    const [setting,settData]=useState({welcome:"",notifications:""});	
	const callpagecontent=()=>{	
    const tokenString = localStorage.getItem("token");                
    const token = JSON.parse(tokenString);  		
		getpagecontent('payment/transactiondashboard',token).then((datatoday)=>{
            //console.log(datatoday)
            setData({today: datatoday.payload.data.today,sdayback:datatoday.payload.data.sdayback,thdayback:datatoday.payload.data.thdayback,payment_details:datatoday.payload.data.payment_details});
        });	 
        
        getpagecontent('company/settings').then((data)=>{
            getpagecontent('notifications/notification').then((datan)=>{            	
                if(data){		
                    settData({welcome:data.payload.data.welcome,notifications:datan.payload.data}) 
                }
            });
        });
	}
	useEffect(()=>{					
		callpagecontent();			
	},[]);
return(
  <>  
            <div className="row">
                <div className=" col-lg-12 col-md-12 col-sm-12">
                    {setting.welcome!="" && (
                        <div className="dwelcime blur-alert">
                            <p>{setting.welcome}</p>
                        </div>
                    )}
                    {setting.notifications.length>0 && (
                        <div className="dwelcime blur-refund">
                        {setting.notifications.map((notification,i) => ( 
                            <p dangerouslySetInnerHTML={{ __html: notification.notification }} />
                        ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className=" col-lg-12 col-md-12 col-sm-12">
                    <div className="Paymentactivity">
                        <div className="row">
                            <div className=" col-lg-12 col-md-12 col-sm-12">
                                <div className="Payment_tittl">Payment activity (USD)</div>
                            </div>
                        </div>

                        <div className="row">
                            <div className=" col-lg-4 col-md-12 col-sm-12">
                                <div className="Paymentbox">
                                    <div className="Paymentboxinner">
                                        <h3>Today's Payment</h3>

                                        <div className="Ptextinside">
                                            <h4>Transactions : <span> {transaction.today.total}</span></h4>

                                            <h4>Amount :<span> {transaction.today.totalamount}</span></h4>
                                        </div>
                                    </div>
                                    <div className="mapp"><img src="images/map.gif" className="img-fluid" alt="" /></div>

                                    <div className="paymenticon"><i className="fas fa-credit-card"></i></div>
                                </div>
                            </div>

                            <div className=" col-lg-4 col-md-12 col-sm-12">
                                <div className="Paymentbox paymentcolor2">
                                    <div className="Paymentboxinner">
                                        <h3>Past 7 day's Payment</h3>

                                        <div className="Ptextinside">
                                            <h4>Transactions : <span> {transaction.sdayback.total}</span></h4>

                                            <h4>Amount :<span> {transaction.sdayback.totalamount}</span></h4>
                                        </div>
                                    </div>
                                    <div className="mapp"><img src="images/map.gif" className="img-fluid" alt="" /></div>

                                    <div className="paymenticon"><i className="fas fa-credit-card"></i></div>
                                </div>
                            </div>

                            <div className=" col-lg-4 col-md-12 col-sm-12">
                                <div className="Paymentbox paymentcolor3">
                                    <div className="Paymentboxinner">
                                        <h3>Past 30 day's Payment</h3>

                                        <div className="Ptextinside">
                                            <h4>Transactions : <span> {transaction.thdayback.total}</span></h4>

                                            <h4>Amount :<span> {transaction.thdayback.totalamount}</span></h4>
                                        </div>
                                    </div>
                                    <div className="mapp"><img src="images/map.gif" className="img-fluid" alt="" /></div>

                                    <div className="paymenticon"><i className="fas fa-credit-card"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className=" col-lg-12 col-md-12 col-sm-12">
                    <div className="Paymentactivity">
                        <div className="row">
                            <div className=" col-lg-12 col-md-12 col-sm-12">
                                <div className="Payment_tittl">Payments Details</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="chart">
                                <div className=" col-lg-12 col-md-12 col-sm-12">
                                <div className="table-responsive">
                                <div className="hometanle">
                                       <table className="table">
                                        <thead className="table-dark">
                                            <tr>
                                                <th scope="col">Reference</th>
                                                <th scope="col">Date and Time</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Amount(USD)</th>
                                                <th scope="col">Currency Mode</th>
                                            </tr>
                                        </thead>
                                        {transaction.payment_details.length > 0 && ( 
                                        <tbody>
                                            {transaction.payment_details.map((payment,i) => (
                                            <tr>
                                                <td>{payment.payment_reference}</td>
                                                <td>{moment(payment.created_at).format("MMMM Do, YYYY HH:MM:SS")}{" "}</td>
                                                <td><span className="status sactive">{payment.status}</span></td>
                                                <td>{payment.payment_receive_amount}</td>
                                                {payment.status!='Completed' && (
                                                <td>-</td>
                                                )}
                                                {payment.status=='Completed' && (
                                                <td>{payment.crypto_currency}</td>
                                                )}
                                            </tr>
                                            ))}                                  
                                        </tbody>                                        
                                        )}
                                        <tfoot>
                                            <tr>
                                                <td align="right" colSpan='5'><a href="/client/transaction">Show More</a></td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>   </div>                   
                </div>
            </div></div>
            <div className="row">
                <div className=" col-lg-12 col-md-12 col-sm-12">
                    <div className="Paymentactivity">
                        <div className="row">
                            <div className=" col-lg-12 col-md-12 col-sm-12">
                                <div className="Payment_tittl">Payment</div>
                            </div>
                        </div>

                        <div className="chart">
                            <div className=" col-lg-12 col-md-12 col-sm-12">
                              
                              <Chart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  </>
)
}
export default Dashboard;