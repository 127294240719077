import {Link,useNavigate} from 'react-router-dom';
import React,{useState} from 'react';
import {postdataa} from '../service/Makerequest';
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";
const Login = ({ setToken }) => {
	const [precaptcha_value_obj, captchadata] = useState({precaptcha_value:'no'});
  //const [notification, serUserData] = useState({ alert: "" });
 // const { dispatch } = useContext(UserContext);
  const history = useNavigate();
  const [user, setUser] = useState({ username: "", password: "" });
  let name, value;

  const handelInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

	////google captcha..
  const recaptchaRef = React.createRef();  
  const onChange=async (value)=> {
   captchadata({precaptcha_value:value});
  }
  
  const postData = async (e) => {
    e.preventDefault();
    ////google captcha..verify
    const recaptchaValue = recaptchaRef.current.getValue();
    if(recaptchaValue===precaptcha_value_obj.precaptcha_value){
      
			const signrespost=await postdataa('users/login',user);
      
        if (signrespost.status >205 || !signrespost) {
          window.alert(signrespost.msg);
        } else {
					//console.log(signrespost.payload.token)
          setToken(signrespost.payload.token);
					localStorage.setItem('username', signrespost.payload.user); 
					localStorage.setItem('currency', signrespost.payload.currency);				 
					
          //dispatch({ type: "User", payload: true });
          //window.alert(signrespost.msg);          
          history("/client/");          
        }    

    }else{
      window.alert('Please check captcha.');
        //serUserData({ alert: resp });
    }
  };
    
return(
<>
	<section className="logInArea">
			<div className="container">
				<div className="row justify-content-center">
				<div className="col-lg-5 col-md-6 col-sm-12">
					<div className="users">
						<div className="logIn">
							<h2>Login</h2>
						</div>
						<form method="post">
							<div className="form-group">
								<label className="control-label">Email <i className="asterred">*</i></label>                
								<input name="username" required="true" className="form-control" placeholder="Email" type="email" onChange={handelInputs}/> 
							</div>
							<div className="form-group">
								<label for="Password" className="control-label">Password <i className="asterred">*</i></label>
								<input name="password" className="form-control" placeholder="Password" type="password" onChange={handelInputs}/>
							</div>								
          		<div className="form-group">
            		<div className="logincaptcha">
            			<ReCAPTCHA ref={recaptchaRef} sitekey="6LdfAtkhAAAAAGzi6mrNYF8miYUqChhNPBYTDHcX" onChange={onChange}/>
								</div>
    					</div>		
							<div className="form-group textCenter">
								<button className="btn btnTheme" onClick={postData} type="submit">Sign in</button> 
							</div>						
							<div className="form-group links">
								<p>Forgot Password? <Link to="/Forgot_password">Forgot password?</Link></p>
								<p>Don't have an account yet ? <Link to="/Register">Click Here</Link></p>
							</div>
						</form>
						
					</div>
				</div>
			</div>
		</div>
	</section>
</>
)
}
export default  Login;
Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};