import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



    function Roadmap() {


   
  var settings = {
    infinite: true,
  slidesToShow: 4,
  slidesToScroll: 4,
  arrows: true,
  autoplay: false,
  autoplaySpeed: 2000,

responsive: [
      {
              breakpoint: 1370,
          settings: {
              slidesToShow: 4,
              slidesToScroll: 4
          }
      },
      {
          breakpoint: 1008,
          settings: {
              slidesToShow: 3,
              slidesToScroll: 3
          }
      },
      {
          breakpoint: 800,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
          // settings: "unslick"
      },

      {
          breakpoint: 767,
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
          // settings: "unslick"
      },

      {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
        // settings: "unslick"
    }


  ]
  };
    return (
      <div>
  
        <Slider {...settings}>
         
		<div>
         
		 <div class="roadmap">
			 <div class="roadmap-box text-center">
				 <div class="date-title">March 2021</div>
				 <div class="map-graphic">
					 <div class="small-round"><span></span></div>
					 <div class="v-row"></div>
				 </div>
				 <div class="roadmap-detail-box">
					 <p>Lorem Ipsum has been the industry's standard dummy text </p>
				 </div>
			 </div>
		 </div>
		 </div>

		 <div>
		 <div class="roadmap">
			 <div class="roadmap-box text-center">
				 <div class="date-title">April 2021</div>
				 <div class="map-graphic">
					 <div class="small-round"><span></span></div>
					 <div class="v-row"></div>
				 </div>
				 <div class="roadmap-detail-box">
					 <p>Lorem Ipsum has been the industry's standard dummy text </p>
				 </div>
			 </div>
		 </div>
		 </div>

		  <div>
			 <div class="roadmap">
			 <div class="roadmap-box text-center">
				 <div class="date-title">May 2021</div>
				 <div class="map-graphic">
					 <div class="small-round"><span></span></div>
					 <div class="v-row"></div>
				 </div>
				 <div class="roadmap-detail-box">
					 <p>Lorem Ipsum has been the industry's standard dummy text </p>
				 </div>
			 </div>
		 </div>
		 </div>

		 <div>   
		 <div class="roadmap">
			 <div class="roadmap-box text-center">
				 <div class="date-title">August 2021</div>
				 <div class="map-graphic">
					 <div class="small-round"><span></span></div>
					 <div class="v-row"></div>
				 </div>
				 <div class="roadmap-detail-box">
					 <p>Lorem Ipsum has been the industry's standard dummy text </p>
				 </div>
			 </div>
		 </div>
		 </div>

		 <div>
			 <div class="roadmap">
			 <div class="roadmap-box text-center">
				 <div class="date-title">August 2021</div>
				 <div class="map-graphic">
					 <div class="small-round"><span></span></div>
					 <div class="v-row"></div>
				 </div>
				 <div class="roadmap-detail-box">
					 <p>Lorem Ipsum has been the industry's standard dummy text </p>
				 </div>
			 </div>
		 </div>
		 </div>

		  <div>
			 <div class="roadmap">
			 <div class="roadmap-box text-center">
				 <div class="date-title">August 2021</div>
				 <div class="map-graphic">
					 <div class="small-round"><span></span></div>
					 <div class="v-row"></div>
				 </div>
				 <div class="roadmap-detail-box">
					 <p>Lorem Ipsum has been the industry's standard dummy text </p>
				 </div>
			 </div>
		 </div>
		 </div>

		  <div>
			 <div class="roadmap">
			 <div class="roadmap-box text-center">
				 <div class="date-title">August 2021</div>
				 <div class="map-graphic">
					 <div class="small-round"><span></span></div>
					 <div class="v-row"></div>
				 </div>
				 <div class="roadmap-detail-box">
					 <p>Lorem Ipsum has been the industry's standard dummy text </p>
				 </div>
			 </div>
		 </div>
		 </div>

		  <div>
			 <div class="roadmap">
			 <div class="roadmap-box text-center">
				 <div class="date-title">August 2021</div>
				 <div class="map-graphic">
					 <div class="small-round"><span></span></div>
					 <div class="v-row"></div>
				 </div>
				 <div class="roadmap-detail-box">
					 <p>Lorem Ipsum has been the industry's standard dummy text </p>
				 </div>
			 </div>
		 </div>
	 </div>



        </Slider>
      </div>
    );
  }


export default Roadmap;