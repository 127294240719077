import {Link,useNavigate} from 'react-router-dom';
import React,{useState}  from 'react';
import {postdataa} from '../service/Makerequest';
function Register (){	

	const history = useNavigate();
	const [signup,setUser]=useState({name:"",email:"",contactNumber:"",currency:"",terms:""})
    let name,value;
    const handelInputs=(e)=>{
        name=e.target.name;
        value=e.target.value;
        setUser({...signup,[name]:value});
    }

    const postData=async(e)=>{
        e.preventDefault();        
        const signrespost=await postdataa('users/create',signup);        
    //console.log(signrespost);
    if(signrespost.status>205 || !signrespost){
        window.alert(signrespost.msg)
				setUser({name:"",email:"",contactNumber:"",currency:"",terms:""})
    }else{
        window.alert(signrespost.msg)
				setUser({name:"",email:"",contactNumber:"",currency:"",terms:""})
				history("/login");
    }   
    }
return(
<>
<section class="logInArea">
    <div class="container">
        <div class="row justify-content-center">
			<div class="col-lg-5 col-md-6 col-sm-12">
				<div class="users">
					<div class="logIn">
						<h2>Register</h2>
					</div>
					<form action="#">
						<div class="form-group">
							<label class="control-label">Full Name</label> 
							<input name="name" value={signup.name} onChange={handelInputs} required="true" class="form-control" placeholder="Full Name" type="text" /> 				
						</div>
		   
						<div class="form-group">
							<label class="control-label">Email <i className="asterred">*</i></label>                
							<input name="email" value={signup.email} onChange={handelInputs} required="true" class="form-control" placeholder="Email" type="email" /> 
						</div>
	
						<div class="form-group">
							<label class="control-label">Contact Number <i className="asterred">*</i></label>                
							<input name="contactNumber" value={signup.contactNumber} onChange={handelInputs} required="true" class="form-control" placeholder="Contact Number" type="text" /> 
						</div>
			
						<div class="form-group">
							<label class="control-label">Preferred currency <i className="asterred">*</i></label>                
							<select name="currency" class="form-control" onChange={handelInputs}>
							<option value="">Select currency</option>
								<option value="USD">USD</option>														
							</select>
						</div>
		
						<div class="form-group chkRegister">
							<label class="control-label"><input onClick={handelInputs} name="terms" type="checkbox" /> I accept the   <Link to="/Terms_and_conditions" target="_blank">Terms and Conditions</Link> <i className="asterred">*</i> </label>
						</div>
		  
						<div class="form-group textCenter">
							<button class="btn btnTheme" onClick={postData}  type="submit">Create Accout</button> 
						</div>

						<div class="form-group links">	
							<p>Do you already have an account? <Link to="/Login">Sign in</Link></p>
						</div>
			
					</form>
				</div>
			</div>
		</div>
	</div>
</section>


</>
)

}
export default  Register;