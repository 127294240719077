import React  from 'react';
import {Outlet} from 'react-router-dom';
import Header from  '../components/Header';
import Footer from  '../components/Footer';
function Home_layout (){
return(
<>
<Header />
  <Outlet />
<Footer />
</>
)}
export default Home_layout;