import { Link,useNavigate} from 'react-router-dom';
import React,{useEffect,useState}  from 'react';
import {frontbasepath,postdataa} from '../service/Makerequest';
import {CopyToClipboard} from 'react-copy-to-clipboard';
function Payment_link_final (){
    const history = useNavigate();
    const [thisdata,setinfo]=useState({invoiceid:""});
    const [copydata,setcopystatus]=useState({copied: false});
        const callfunction = async () => {
                const invoiceid = localStorage.getItem('invoiceid');                 
                if(invoiceid!=='false'){                                  
                setinfo({
                    invoiceid:invoiceid
                });
            }else{
                history('/client/payment-link'); 
            }
        };
        useEffect(() => {
            callfunction();
        }, []);   
        const copied=()=>{
            setcopystatus({copied: true});
            setTimeout(() => {
                setcopystatus({copied: false});
            }, 2000);
        }  
        
        const postdata=async(e)=>{
            e.preventDefault(); 
            const tokenString = localStorage.getItem("token");                
            const token = JSON.parse(tokenString);                                                            
            const respost=await postdataa('payment/send/link',thisdata,token);                  
            if (respost.status >205 || !respost) {
                    window.alert(respost.msg);
            } else {                       
                    localStorage.setItem('invoiceid',false); 
                    window.alert(respost.msg);                                  
            }        
        }

    return(
        <>
        <div className="paymentrequestmain">
            <div className=" col-lg-12 col-md-12 col-sm-12">
                <div className="paymentittle">
                    <h2>Make a payment request</h2>
                    <p>Send a payment request via Email and receive payment in crypto.</p>
                </div>
            </div>
            <div className=" col-lg-6 col-md-12 col-sm-12">
                <div className='paymentlinkbox'>
                    <div className='paymenttop'>
                        <p>Payment Request created ! How do you want to inform your payer of this payment request ?</p>
                        <button onClick={postdata} type="button" className="btn btnTheme">Send email to payer</button>
                    </div>
                    <div className='orpayment'>
                        <h6>or</h6>
                    </div>
                    <div className='paymenttopcopymain'>
                        <h3>Copy the following link and send it to your payer.</h3>
                        <div className='paymenttopcopy'>
                            <span>{frontbasepath}/invoice/{thisdata.invoiceid}</span>
                            <CopyToClipboard text={frontbasepath+'/invoice/'+thisdata.invoiceid}
                                onCopy={copied}>                            
                                <button className='copybutton'><i className="fas fa-copy"></i></button>
                            </CopyToClipboard>
                        </div>
                        {copydata.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
                    </div>
                    <small>Please note that the payer will have to upload their identity document (for individual) or business registration document (for company) for verification before payment*.</small>
                    <cite>*This is only for payer who have not uploaded the document before.</cite>
                    <div className='backpayment'>
                        <Link to="/client/payment-link" className="btn btnTheme btnBlue ">Create another payment request</Link>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default Payment_link_final ;