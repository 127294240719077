
import React,{useEffect,useState} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import {getpagecontent} from '../service/Makerequest';
import { Dna } from 'react-loader-spinner'

function Faq() {
  const [faqdata,setfaqData]=useState({'payload':'','body':'','loader':true});		
	const callpagecontent=async()=>{			
		const data=await getpagecontent('faqs/faqs');				
		setfaqData({'payload':data.payload,'body':data.payload.data,'loader':false}) 	
	}
	useEffect(()=>{					
		callpagecontent();			
	},[]);
  return (
   <>
  <Dna
			visible={faqdata.loader}
			height="80"
			width="80"
			ariaLabel="dna-loading"
			wrapperStyle={{'margin':'0 50%'}}
			wrapperClass="dna-wrapper"
		/>
<section class="faq_section">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
				<div class="headingOther">
					<h2><span>Frequently</span> Asked Questions</h2>
				</div>
			</div>
		</div>
		<div class="row"> 
			<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
        {faqdata.body.length > 0 && (
				  <div class="faq_content">
            <Accordion defaultActiveKey="0">
              {faqdata.body.map((faq,i) => (				      
                <Accordion.Item eventKey={'h'+i}>
                  <Accordion.Header>{faq.question}</Accordion.Header>
                  <Accordion.Body>
                    {faq.answer}
                  </Accordion.Body>
                </Accordion.Item>
              ))}      
            </Accordion>
          </div>
        )}
			</div>
		</div>
	</div>
</section>   

</>
  );
}

export default Faq;
