import React,{useState,useEffect}  from 'react';
import { Link} from 'react-router-dom';
//import React, { useState } from 'react';
//import Button from 'react-bootstrap/Button';
//import Offcanvas from 'react-bootstrap/Offcanvas';

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {getpagecontent,serverbasepath} from '../service/Makerequest';
const Buttonsauth=()=>{		
	return (
		<>	
			<li class="nav-item nav-itemBtn">
				<Link to="/client/" class="btn btnTheme btnReg">Account</Link>
			</li>
			<li class="nav-item nav-itemBtn">
				<Link to="/logout" class="btn btnTheme">Logout</Link>
			</li>
	</>
	)
}
const Buttons=()=>{
	return (
		<>
	<li class="nav-item nav-itemBtn">
		<Link to="/Login" class="btn btnTheme">Sign in</Link>
	</li>
	<li class="nav-item nav-itemBtn">
		<Link to="/Register" class="btn btnTheme btnReg">Sign Up</Link>
	</li>
	</>
	)		
}
const Header = () => {
	const [companyinfodata,settData]=useState({'payload':'','body':{company_logo:"logo-logo1662642491241.png"},'loader':true});		
	const callfootercontent=async()=>{			
	 	const data=await getpagecontent('company/settings');	
		if(data){		
	 		settData({'payload':data.payload,'body':data.payload.data,'loader':false}) 
		}	
	}
	useEffect(()=>{					
	 	callfootercontent();			
	},[]);

	const [checkauth, getauth] = useState({isauthenticate:true});
	useEffect(() => {
    const token = localStorage.getItem('token');		
    if(!token) {
      getauth({isauthenticate:false})
    }
  });		
	return(
		<>
		<Container>
			<Navbar bg="light" expand="lg">    
				<Link to="/" class="navbar-brand"><img src={serverbasepath+"/uploads/"+companyinfodata.body.company_logo} alt=""/></Link>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className="ml-auto navmenu">
							<li><Link to="/" className='nav-link'>Home</Link></li>
							<li>
								<NavDropdown title="Services" id="basic-nav-dropdown">             
									<li><Link to="/crypto-payments-for-psps" class="dropdown-item" >Crypto payment for retail POS</Link>  </li>
									<li><Link to="/crypto-payment-for-ecommerce" class="dropdown-item" >Crypto payment for Ecommerce</Link></li>
									<li><Link to="/crypto-payment-by-invoicing" class="dropdown-item" >Crypto payment by invoicing</Link></li>
									<li><Link to="/crypto-payment-for-psps" class="dropdown-item" >Crypto payment for PSPs</Link></li>
									<li>	<Link to="/crypto-payment-for-remittance"  class="dropdown-item">Crypto payment for Remittance</Link></li>
									<li><Link to="/crypto-trading-for-digital-wallet" class="dropdown-item" >Crypto Trading for digital wallet</Link></li>
								</NavDropdown>
							</li>
							<li>
								<NavDropdown title="Company" id="basic-nav-dropdown">
									<li><Link to="/about-us" class="dropdown-item">About us</Link></li>
									<li><Link to="/newsroom" class="dropdown-item">Newsroom</Link></li>
									<li><Link to="/terms-and-conditions" class="dropdown-item">Privacy & terms</Link></li>
									<li><Link to="/careers" class="dropdown-item">Careers</Link></li>
									<li><Link to="/faq" class="dropdown-item">Faq</Link></li>
								</NavDropdown>
							</li>
							<li>
								<NavDropdown title="Resources" id="basic-nav-dropdown">             
									<li><Link to="/crypto-ownership-data" class="dropdown-item">Crypto Ownership Data</Link></li>
									<li><Link to="/Blog" class="dropdown-item">Blogs</Link></li>
									<li><Link to="/whitepaper" class="dropdown-item">Whitepaper</Link></li>

									<li><Link to="/contact" class="dropdown-item">Support</Link></li>
									<li><Link to="/api-documentation" class="dropdown-item">API Documentation</Link></li>
									<li><Link to="/Exchange_rates" class="dropdown-item">Exchange rate</Link></li>
								</NavDropdown>
							</li>
							<li><Link to="/Contact" className='nav-link'>Contact</Link></li>
							{checkauth.isauthenticate===false &&
								<Buttons/>
							}
							{checkauth.isauthenticate===true &&
								<Buttonsauth/>
							}
						</Nav>
					</Navbar.Collapse>
			</Navbar>
		</Container>
		</>
	)
}
export default Header;