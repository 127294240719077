//import { Link} from 'react-router-dom';
//import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import Roadmap from '../Home_component/Roadmap';
import {getpagecontent} from '../service/Makerequest';
import { Dna } from 'react-loader-spinner'
 import React,{useEffect,useState} from 'react'
// export const userData={};

function Home (){	
	const [userData,serUserData]=useState({'payload':'','body':'','easytouse':'','first':'','second':'','third':'','fourth':'','roadmap':'','developerfriendly':'','loader':true}); 
	const callpagecontent=async()=>{   
		const data=await getpagecontent('home/content/home');
		const easytouse=await getpagecontent('home/content/home-easy-to-use');	
		const first=await getpagecontent('home/content/home-easy-to-use-first');
		const second=await getpagecontent('home/content/home-easy-to-use-second');
		const third=await getpagecontent('home/content/home-easy-to-use-third');
		const fourth=await getpagecontent('home/content/home-easy-to-use-fourth');
		const roadmap=await getpagecontent('home/content/home-roadmap');
		const developerfriendly=await getpagecontent('home/content/home-developer-friendly');
		
		if(data){                 
			serUserData({'payload':data.payload,'body':data.payload.data.body,'easytouse':easytouse.payload.data.body,'first':first.payload.data.body,'second':second.payload.data.body,'third':third.payload.data.body,'fourth':fourth.payload.data.body,'roadmap':roadmap.payload.data.body,'developerfriendly':developerfriendly.payload.data.body,'loader':false}) 
		}         
	}
	useEffect(()=>{
		callpagecontent();
	},[]);
//console.log(userData,'hhh');
return(
<>
<Dna
  visible={userData.loader}
  height="80"
  width="80"
  ariaLabel="dna-loading"
  wrapperStyle={{'margin':'0 50%'}}
  wrapperClass="dna-wrapper"
/>
<div dangerouslySetInnerHTML={{ __html: userData.body }} />

<section class="easyMain">
	<div class="container">
		<div class="row">
			<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
				<div class="headingOther">
					<div dangerouslySetInnerHTML={{ __html: userData.easytouse }} />
				</div>
			</div>
		</div>
		<div class="tabAreaHome">
		<Tab.Container  defaultActiveKey="first">
      <Row>     
        <Nav variant="  nav-tabs " >
          <Nav.Item><Nav.Link eventKey="first">Startups</Nav.Link></Nav.Item>
          <Nav.Item> <Nav.Link eventKey="second"> Ecommerce</Nav.Link></Nav.Item>
					<Nav.Item><Nav.Link eventKey="third">Invoicing</Nav.Link></Nav.Item>
          <Nav.Item> <Nav.Link eventKey="fourth">In-person & Live evants</Nav.Link></Nav.Item>
        </Nav>
				<Tab.Content>
					<Tab.Pane eventKey="first">
						<div dangerouslySetInnerHTML={{ __html: userData.first }} />
					</Tab.Pane>
					<Tab.Pane eventKey="second">
						<div dangerouslySetInnerHTML={{ __html: userData.second }} />
					</Tab.Pane>			
					<Tab.Pane eventKey="third">
						<div dangerouslySetInnerHTML={{ __html: userData.third }} />
					</Tab.Pane>
					<Tab.Pane eventKey="fourth">
						<div dangerouslySetInnerHTML={{ __html: userData.fourth }} />
					</Tab.Pane>
				</Tab.Content>   
      </Row>
    </Tab.Container>
	</div>
</div>
</section>

<section className="roadmapMain">
	<div className="container">
		<div className="row">
			<div className="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
				<div className="headingOther">
					<div dangerouslySetInnerHTML={{ __html: userData.roadmap }} />
				</div>
			</div>
		</div>
		<div className="main-roadmap">


		<div class="row">
				<div class="col-md-12">
					<div class="h-border wow fadeInLeft" ></div>
				</div>
			</div>

			<div class="row">
				<div class="col-md-12">
				<div class="roadmap-slider">
					<Roadmap />

					</div>		</div>		</div>
		</div>
	
	
	
 </div>
</section>
	<div dangerouslySetInnerHTML={{ __html: userData.developerfriendly }} />
</>
)



}
export default Home;