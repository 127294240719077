import React, { useState }  from 'react';
import {Link} from 'react-router-dom';
import {postdataa} from '../service/Makerequest';
import {useNavigate} from 'react-router-dom';
function Changepassword() {	
  const history = useNavigate();
  const [user, setUser] = useState({old_password: "",new_password: "",confirm_password: "" });
  let name, value;

  const handelInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };

	const postData = async (e) => {
    e.preventDefault();  
      user.token=JSON.parse(localStorage.getItem('token'));   
			const signrespost=await postdataa('users/changepassword',user);      
        if (signrespost.status >205 || !signrespost) {
          window.alert(signrespost.msg);
        } else {					
          window.alert(signrespost.msg);          
          history("/client/");          
        }     
  };
  return(
    <>
   
          <div className="row justify-content-center">
     
            
              <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="changepw changecommon">
        
              <h2>Change Password</h2>
            

            <div className="changepwtittle">
            <form method="post">
              <div className="form-group">
                <label class="control-label">Old Password</label> 
                <input name="old_password" className="form-control" placeholder="" type="password" onChange={handelInputs}/>
              </div>
              <div className="form-group">
                <label class="control-label">New Password</label> 
                <input name="new_password" className="form-control" placeholder="" type="password" onChange={handelInputs}/>
              </div>
              <div className="form-group">
                <label class="control-label">Confirm Password</label>
                <input name="confirm_password" className="form-control" placeholder=" " type="password" onChange={handelInputs}/>
              </div>             	
          
              <div className="formroup textCenter">
                <button onClick={postData} className="btn btnTheme" type="submit">Submit</button> 
              </div>             
            </form>
          </div>
        </div>
      </div>

      <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="profileedit changecommon">

        <aside>  <h2>View Your Profile</h2>
          <Link to="/client/viewProfile"  className="btn btnTheme btnBlue ">View </Link>
          </aside>
         <figure><img src="../images/useredit.png" className="img-fluid" alt="" /></figure> 
       


          </div>
      </div>








      </div>
    </>
  )
}
export default Changepassword;