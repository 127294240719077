import React, { useEffect,useState }  from 'react';
import {getpagecontent,serverbasepath} from '../service/Makerequest';
import { Dna } from 'react-loader-spinner'
function Blog() {	
  const [blogdata,setblogData]=useState({'payload':'','body':'','loader':true});		
	const callpagecontent=async()=>{			
		const data=await getpagecontent('blogs/blogs');				
		setblogData({'payload':data.payload,'body':data.payload.data,'loader':false}) 	
	}
	useEffect(()=>{					
		callpagecontent();			
	},[]);
	
  return (
   <>
		<Dna
			visible={blogdata.loader}
			height="80"
			width="80"
			ariaLabel="dna-loading"
			wrapperStyle={{'margin':'0 50%'}}
			wrapperClass="dna-wrapper"
		/>
<section class="blogMain">
	<div class="container"> 
		<div class="row">
			<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
				<div class="headingOther">
					<h2><span>Read about </span>the Future of Crypto Payments</h2>
				</div>
			</div>
		</div>		
		{blogdata.body.length > 0 && (	
			<div class="row">		
			{blogdata.body.map(blog => (
				<div class="col-lg-4 col-md-6 col-sm-12 blogRES">
					<div class="blogInner">
						<figure>
							<img class="img-fluid" src={serverbasepath+"/uploads/"+blog.image} alt="" />
						</figure>
						
						<div class="adminData">
							<h4>{blog.title}</h4>
							<div class="posted">
								<p>Posted by <a href="/blog/readmore/">{blog.posted_by}</a> | <a href="/blog/readmore/">10 Comments</a></p>
							</div>
							<p>{blog.body}</p>
							<a href={"/blog/readmore/"+blog.slug} class="btn btnTheme">Read more</a>
						</div>
					</div>
				</div>
			))}
			</div>
		)}	
	</div>
</section> 




   
   </>


  );
}

export default Blog;
