import React, { useEffect,useState }  from 'react';
import {Link} from 'react-router-dom';
import {getpagecontent,postdataa} from '../service/Makerequest';
import { Dna } from 'react-loader-spinner'
import {useNavigate} from 'react-router-dom';
function Changepassword() {	
  const [user, setUser] = useState({ name: "",contactNumber: "",email:"" });
  const [profiledata,setprofileData]=useState({'payload':'','body':'','loader':true});		
	const callprofile=async()=>{	
    var token=JSON.parse(localStorage.getItem('token'));		
		const data=await getpagecontent('users/profileinfo/'+token);		
    setUser({name:data.payload.data.name,contactNumber:data.payload.data.contactNumber,email:data.payload.data.email})		
		setprofileData({'payload':data.payload,'body':data.payload.data,'loader':false}) 	
	}
	useEffect(()=>{					
		callprofile();			
	},[]);
  const history = useNavigate();
  
  let name, value;

  const handelInputs = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUser({ ...user, [name]: value });
  };	

	const postData = async (e) => {
    e.preventDefault(); 
      user.token=JSON.parse(localStorage.getItem('token'));    
			const signrespost=await postdataa('users/editprofile',user);      
        if (signrespost.status >205 || !signrespost) {
          window.alert(signrespost.msg);
        } else {					
          window.alert(signrespost.msg);          
          history("/client/viewProfile");          
        }     
  };
  return(
    <>
    <Dna
        visible={profiledata.loader}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{'margin':'0 50%'}}
        wrapperClass="dna-wrapper"
      />


<div className="row justify-content-center">
<div className="col-lg-6 col-md-12 col-sm-12">
          <div className="changepw changecommon">
        
              <h2>Edit Profile</h2>
            

            <div className="changepwtittle">
            <form method="post">
              <div className="form-group">
                <label class="control-label">Name</label> 
                <input name="name" className="form-control" value={user.name} placeholder="Name" type="text" onChange={handelInputs}/>
              </div>
              <div className="form-group">
                <label class="control-label">Email</label> 
                <input name="email" className="form-control" value={user.email} placeholder="Email" type="email" readOnly/>
              </div>
              <div className="form-group">
                <label class="control-label">Phone no.</label>
                <input name="contactNumber" className="form-control" value={user.contactNumber} placeholder="Mobile no." type="text" onChange={handelInputs}/>
              </div>              	
          
              <div className="form-group textCenter">
                <button onClick={postData} className="btn btnTheme" type="submit">Submit</button> 
              </div>             
            </form>
          </div>
        </div>
      </div>


   

      </div>

  
     
   
    </>
  )
}
export default Changepassword;