import React,{useEffect,useState}  from 'react';
import {Link,useNavigate} from 'react-router-dom';
//import Container from 'react-bootstrap/Container';
//import Nav from 'react-bootstrap/Nav';
//import Navbar from 'react-bootstrap/Navbar';
//import NavDropdown from 'react-bootstrap/NavDropdown';
import {getpagecontent} from '../service/Makerequest';
import '../css/account/dashboard.css';
import '../css/account/responsive.css'; 
function Account_header (props){
  const [companyinfodata,settData]=useState({'payload':'','body':{company_logo:"logo-logo1662642491241.png"},'loader':true,username:"",currency:""});		
	const callfootercontent=async()=>{			
	 	const data=await getpagecontent('company/settings');	
		if(data){		
	 		settData({'payload':data.payload,'body':data.payload.data,'loader':false,username:"",currency:""}) 
		}	
	}
	useEffect(()=>{					
	 	callfootercontent();			
	},[]);
  const history = useNavigate();
  useEffect(() => {
    settData({'username':localStorage.getItem('username'),'currency':localStorage.getItem('currency')});
    const token = localStorage.getItem('token');
    if(!token) {
      history("/login");
    }
  });
return(
<>
<div className="dashheader">
<button className="clickmenu" onClick={props.toggleMenu}>
  <img src="../images/menu.png" className="img-fluid"/></button>
  
            <div className="welcometext"><h4></h4></div>

            <div className="profile">
                <div className="username">
								<Link to="/client/viewProfile"> <i><img src="images/user-286.png" className="img-fluid" alt="" /></i>
                    <p className="name">
                        {companyinfodata.username} <span className="cureency"> ({companyinfodata.currency})</span>
                        <cite className="fas fa-chevron-right"></cite>
                    </p>
										</Link>
                </div>
            </div>
        </div>






</>


)



}

export default Account_header ;