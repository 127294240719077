import React,{useEffect,useState}  from 'react';
import {Outlet} from 'react-router-dom';
import Account_header from  '../account/Account_header';
import Footer from  '../account/Account_footer';
import DashboardLeft from '../account/Dashboard_left';
function Account_layout (props  ){

  const [isActive, setActive] = useState("");

  const toggleMenu = () => {
    setActive(!isActive);
  }



  const hidemenu = () => {
    setActive(!isActive);
    
  };



return(
<>
<div className={`app ${isActive ? "closeslide" : " openslide"}`}>
<div className="dashboardmain">
<DashboardLeft  hidemenu={hidemenu}/>

<div className="dashrightside">
     
     <div className="dleftsideinner">
<Account_header toggleMenu={toggleMenu}/>
  <Outlet />
  </div>  </div>  </div>


<Footer /></div>
</>
)}
export default Account_layout;