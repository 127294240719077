import React, { useEffect, useState } from "react";
import {getpagecontent } from "../service/Makerequest";
import {useLocation } from 'react-router-dom';
import { Dna } from 'react-loader-spinner'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import moment from "moment";
import Countdown from 'react-countdown';
//import {creare_payment} from "./gateway/coinpayment";
function Invoiceform() {
       // const history = useNavigate();
        const location = useLocation()
        const [invoiceinfo, setinvoiceinfo] = useState({invoice: "", address:"", qrcode:"",amount:"",checkout_url:"",'loader':true });
        const [copydata,setcopystatus]=useState({copied: false});
        const [timeoutdt,settimeoutt]=useState({timeout: 2000});
        const getpaymentaddress = async() => {
                
                const url = location.pathname;
		const lastSegment = url.split("/").pop();
                getpagecontent('payment/generateaddress/'+lastSegment).then((data)=>{ 
                        if(data.status>205){
                                setinvoiceinfo({invoice:"", address:"", qrcode:"",amount:"",checkout_url:"",'loader':false})
                                window.alert(data.msg);
                        }else{                                                  
                                setinvoiceinfo({invoice:data.payload.data.invoicedata,qrcode:data.payload.data.qrcode_url,address:data.payload.data.address,amount:data.payload.data.amount,checkout_url:data.payload.data.checkout_url,'loader':false}) 
                                settimeoutt({timeout:(data.payload.data.timeout*1000)}); 
                        }
                }); 
                // const data=await creare_payment();
                // console.log(data,'fff');
        };
        // Renderer callback with condition
        const renderer = ({minutes, seconds, completed }) => {
                
                if (completed) {
                // Render a completed state
                window.alert('Payment Form has expired. No full payment is detected.');
                return '<span> 00:00 </span>';
                } else {
                // Render a countdown
                
                return <span> {minutes}:{seconds} </span>;
                }
        };
        useEffect(() => {
                getpaymentaddress();
        }, []); 
        
        const copied=()=>{
                setcopystatus({copied: true});
                setTimeout(() => {
                    setcopystatus({copied: false});
                }, 2000);
            }        
        return (
                <>
                        <Dna
                                visible={invoiceinfo.loader}
                                height="80"
                                width="80"
                                ariaLabel="dna-loading"
                                wrapperStyle={{'margin':'0 50%'}}
                                wrapperClass="dna-wrapper"
                        />
                        <div className="payment_formmain">
                                <div className="row justify-content-center">
                                        <div className=" col-lg-4 col-md-12 col-sm-12">
                                        <div className="payment_form">
                                                <div className="paymentform_tittle">
                                                <h2>Invoice<date>{moment(invoiceinfo.invoice.created_at).format("MMMM Do, YYYY")}{" "}</date></h2>
                                                </div>

                                                <div className="paymentform_name">{invoiceinfo.invoice.payer_name}</div>

                                                <div className="pamountboxmain">
                                                <div className="pamountbox">
                                                        <small>Total</small>
                                                        <h3>{invoiceinfo.invoice.payment_receive_amount} {invoiceinfo.invoice.payment_currency}</h3>
                                                </div>

                                                <div className="pamountbox">
                                                        <small>Amount</small>
                                                        <h3>{invoiceinfo.amount} {invoiceinfo.invoice.crypto_currency}</h3>
                                                </div>

                                                <div className="adreesscode" style={{"display":"none"}}>
                                                        <h4>Please send to address</h4>

                                                        <div className="adcode">
                                                                
                                                        <span>{invoiceinfo.address}</span>
                                                        <CopyToClipboard text={invoiceinfo.address} onCopy={copied}>
                                                                <button><i class="far fa-copy"></i></button>
                                                        </CopyToClipboard>
                                                        </div>
                                                        {copydata.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
                                                </div>

                                                <div className="barcodee">
                                                        <h4>Scan the QR code</h4>
                                                        <figure><img src={invoiceinfo.qrcode} className="img-fluid" alt="" /></figure>

                                                        <a href="javascript:void(0)">Facing issue? Click to scan simplified QR code</a>
                                                </div>

                                                

                                                <div className="barcodeebtn">
                                                        <a target="_blank" href={invoiceinfo.checkout_url} class="btn btnTheme">Open in Wallet</a>
                                                </div>

                                                <div className="barPRICE">
                                                        <p><i class="far fa-clock"></i> 
                                                        <Countdown date={Date.now() + parseInt(timeoutdt.timeout)} renderer={renderer}>
      
    </Countdown>                                               
                                                        
                                                         Remaining</p>
                                                        <p>1 {invoiceinfo.invoice.sandbox?'test':''} {invoiceinfo.invoice.crypto_currency} = 1 {invoiceinfo.invoice.payment_currency}</p>
                                                </div>
                                                </div>
                                        </div>
                                        </div>
                                </div>
                        </div>
                </>
        );
}
export default Invoiceform;
