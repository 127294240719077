import React,{useState} from 'react';
import {useLocation } from 'react-router-dom';
import {getpagecontent} from '../service/Makerequest';
import { Dna } from 'react-loader-spinner'
function Crypto_payments_invoicing() {	 
	const [useefeect,setuseefct]=useState('n');
  const [userData,serUserData]=useState({'payload':'','body':'','loader':true}); 
	
	const location = useLocation()	
	const callpagecontent=async()=>{ 	
		const url = location.pathname;
		const lastSegment = url.split("/").pop();
		setuseefct(lastSegment)		
		const data=await getpagecontent('home/content/'+lastSegment);				
		serUserData({'payload':data.payload,'body':data.payload.data.body,'loader':false}) 		
	}	
	
	const url = location.pathname;
	const lastSegment = url.split("/").pop();	
	if(useefeect!==lastSegment){	
		//useEffect(()=>{					
			callpagecontent();			
		//},[]);		
	}
  return (
   <>
	 <Dna
  visible={userData.loader}
  height="80"
  width="80"
  ariaLabel="dna-loading"
  wrapperStyle={{'margin':'0 50%'}}
  wrapperClass="dna-wrapper"
/>
	<div dangerouslySetInnerHTML={{ __html: userData.body }} />
</>
  );
}

export default Crypto_payments_invoicing;
