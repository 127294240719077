
import React,{useEffect,useState, state}  from 'react';
import {Link} from 'react-router-dom';
import {getpagecontent,serverbasepath} from '../service/Makerequest';
function Dashboard_left (hidemenu){
    const [companyinfodata,settData]=useState({'payload':'','body':{company_logo:"logo-logo1662642491241.png"},'loader':true});	
    const [totalbalance,setbalance]=useState({'totalbalance':''});		
	const callfootercontent=async()=>{			
	 	const data=await getpagecontent('company/settings');	
		if(data){		
	 		settData({'payload':data.payload,'body':data.payload.data,'loader':false}) 
		}	
        const tokenString = localStorage.getItem("token");                
        const token = JSON.parse(tokenString);
        getpagecontent('payment/totalbalance',token).then((totalbalances)=>{            
            setbalance({totalbalance: totalbalances.payload.data.total});
        });
	}
	useEffect(()=>{					
	 	callfootercontent();			
	},[]);
  


return(
<>



    <div className="dashleftside">
    <div className="dleftsideinner">
        <div className="dashlogo dashleftpadding">
        <Link to="/client/"><img src={serverbasepath+"/uploads/"+companyinfodata.body.company_logo} alt=""/></Link>
        </div>
        
        <button className="closemenu" onClick={hidemenu.hidemenu}>
 <img src="../images/close.png" className="img-fluid"/>
  
  
  </button>
    
  



        <div className="balnacebox">
            <h5>Total Balance</h5>
            <h2>USD {totalbalance.totalbalance}</h2>
            
        </div>

        <div className="dleftmenu dashleftpadding">
            <div className="menus">
                <ul>
                    <h2>Overview</h2>
                    <li>
                    <Link to="/client/">
                            <i className="fas fa-tachometer-alt"></i>
                            <p>Dashboard</p>
                            </Link>
                    </li>
                    <li>
                        <Link to="/client/transaction">
                            <i className="far fa-credit-card"></i>
                            <p>Transactions</p>
                        </Link>
                    </li>
                    <li>
                        <Link to="/client/invoice">
                            <i className="far fa-credit-card"></i>
                            <p>Invoice List</p>
                        </Link>
                    </li>

                    <li>
                        <Link to="/client/">
                            <i className="fas fa-undo"></i>
                            <p>Refunds</p>
                        </Link>
                    </li>

                    <h2>Tools</h2>
                    <li>
                        <Link to="/client/payment-link">
                        <i className="fas fa-file-alt"></i>
                            <p>Invoicing</p>
                        </Link>
                    </li>







                    <h2>Account</h2>
                    <li>
                        <Link to="/client/viewProfile">
                            <i className="fas fa-user"></i>
                            <p>View Profile</p>
                        </Link>
                    </li>


                    <li>
                    <Link to="/client/changePassword">
                            <i className="fas fa-lock"></i>
                            <p>Change password</p>
                            </Link>
                    </li>
                    

                </ul>
            </div>
        </div>




        <div className="loginbtnn">
                    <Link to="/logout" className="btnlogout">Logout</Link>
                    </div>




    </div>

    
</div>










</>

)
}

export default Dashboard_left;