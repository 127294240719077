import React, { useEffect,useState }  from 'react';
import {getpagecontent} from '../service/Makerequest';
import { Dna } from 'react-loader-spinner'
import {Link} from 'react-router-dom';
function ViewProfile() {	
  const [profiledata,setprofileData]=useState({'payload':'','body':'','loader':true});		
	const callprofile=async()=>{		
    const tokenString = localStorage.getItem('token');
    const token = JSON.parse(tokenString);
		const data=await getpagecontent('users/profileinfo/'+token);				
		setprofileData({'payload':data.payload,'body':data.payload.data,'loader':false}) 	
	}
	useEffect(()=>{					
		callprofile();			
	},[]);
  return(
    <>
    <Dna
        visible={profiledata.loader}
        height="80"
        width="80"
        ariaLabel="dna-loading"
        wrapperStyle={{'margin':'0 50%'}}
        wrapperClass="dna-wrapper"
      />
       



       <div className="row justify-content-center">

 
    <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="changepw view_common">
        
              <h2>{profiledata.body.name}</h2>
            
              <div className="profile_detail ">  
                  <p><strong>Email:</strong> {profiledata.body.email}</p>
                  <p><strong>Phone No.:</strong> {profiledata.body.contactNumber}</p>
                  <p><strong>Currency: </strong>{profiledata.body.currency}</p>               
                  
                  </div>
        </div>
      </div>









    

    <div className="col-lg-6 col-md-12 col-sm-12">
          <div className="profileedit view_common">

        <aside>  <h2>Edit Your Profile</h2>
        <Link to="/client/editprofile" class="btn btnTheme">Edit</Link>
          </aside>
         <figure><img src="../images/useredit.png" className="img-fluid" alt="" /></figure> 
       


          </div>
      </div>

      </div>








    </>
  )
}
export default ViewProfile;