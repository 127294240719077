
import React,{useEffect,useState}  from 'react';
//import { useHistory } from 'react-router-dom';
import {getpagecontent,postdataa} from '../service/Makerequest';
function Contact() {
    //const history=useHistory();
    const [companyinfodata,settData]=useState({'payload':'','body':'','loader':true});		
	const callfootercontent=async()=>{			
	 	const data=await getpagecontent('company/settings');				
	 	settData({'payload':data.payload,'body':data.payload.data,'loader':false}) 	
	}
	useEffect(()=>{					
	 	callfootercontent();			
	},[]);
    const [contactus,setUser]=useState({name:"",email:"",subject:"",message:""})
    let name,value;
    const handelInputs=(e)=>{
        name=e.target.name;
        value=e.target.value;
        setUser({...contactus,[name]:value});
    }

    const postData=async(e)=>{
        e.preventDefault();        
        const contactpost=await postdataa('contactus/contactus',contactus);        
    //console.log(contactpost);
    if(contactpost.status===422 || !contactpost){
        window.alert('Invalid Data')
    }else{
        window.alert('Mail sent successfully')
    }
   // history.push('/signin');
    }
  return (
   


    <section className="contactMain">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                    <div className="contactRight">
                        <h4>Contact Us</h4>
                        <h2>Get In Touch.</h2>
                        <form method="post">
                            <div className="row">   
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Name</label>                
                                        <input required="true" className="form-control" name="name" value={contactus.name} onChange={handelInputs} placeholder="Name" type="text" />
                                    </div>
                                </div> 
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Email</label>                
                                        <input required="true" className="form-control" placeholder="Email" name="email" value={contactus.email} onChange={handelInputs} type="email" />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Subject</label>                
                                        <input required="true" className="form-control" name="subject" value={contactus.subject} onChange={handelInputs} placeholder="Subject" type="text" />                
                                    </div>
                                </div>
                                
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="form-group">
                                        <label className="control-label">Message </label>            
                                        <textarea name="message" onChange={handelInputs} placeholder="Message..." className="form-control" rows="3">{contactus.message}</textarea>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="btnContact">
                                        <button onClick={postData} className="btn btnTheme" type="submit">Contact Us</button> 
                                    </div>
                                </div>
                            </div>	
                        </form>
                    </div>
                </div>
            
                <div className="col-xl-5 offset-xl-1 col-lg-6 col-md-6 col-sm-12">
                    <div className="contactLeft">
                        <ul>
                            <li>
                                <figure>
                                    <img src="images/email.png" alt=''/>
                                </figure>
                                <div className="corporateRight">				
                                    <h3>Email</h3>
                                    <p>{companyinfodata.body.company_email}</p>
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img src="images/phone.png" alt=''/>
                                </figure>
                                <div className="corporateRight">				
                                    <h3>Phone</h3>
                                    <p>{companyinfodata.body.company_phone}</p>                                    
                                </div>
                            </li>
                            <li>
                                <figure>
                                    <img src="images/address.png" alt=''/>
                                </figure>
                                <div className="corporateRight">				
                                    <h3>Address</h3>
                                    <p>{companyinfodata.body.company_address}</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>		
    </section>

    


  );
}

export default Contact;
