
import { Link} from 'react-router-dom';
import React,{useEffect,useState}  from 'react';
import {getpagecontent,postdataa} from '../service/Makerequest';
function Footer (){
	const [companyinfodata,settData]=useState({'payload':'','body':'','loader':true});		
	const callfootercontent=async()=>{			
	 	const data=await getpagecontent('company/settings');	
		if(data){		
	 		settData({'payload':data.payload,'body':data.payload.data,'loader':false}) 
		}	
	}
	useEffect(()=>{					
	 	callfootercontent();			
	},[]);

	const [subscriber,setUser]=useState({email:""})
    let name,value;
    const handelInputs=(e)=>{
        name=e.target.name;
        value=e.target.value;
        setUser({...subscriber,[name]:value});
    }

    const postData=async(e)=>{
        e.preventDefault();        
        const subscribepost=await postdataa('subscribe/subscribe',subscriber);        
    //console.log(contactpost);
    if(subscribepost.status===422 || !subscribepost){
        window.alert('Invalid Data')
    }else{
        window.alert('Your subscriptions are now successfully registered.')
    }
   // history.push('/signin');
    }
	
  return(
    <>
<div className="footer" style={{display:"block"}} >

	<div class="container">	


	<div class="footerTop">
			<div class="row justify-content-lg-between">



			<div class="col-lg-3 col-md-12 col-sm-12">
				<div class="socialIcon">
							<ul>
								<li><a href={companyinfodata.body.facebook_link} target="_blank" rel="noopener noreferrer"><i class="fab fa-facebook-f"></i></a></li>
								<li><a href={companyinfodata.body.twitter_link} target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter"></i></a></li>
								<li><a href={companyinfodata.body.linkedin} target="_blank" rel="noopener noreferrer"><i class="fab fa-linkedin-in"></i></a></li>
								<li><a href={companyinfodata.body.google} target="_blank" rel="noopener noreferrer"><i class="fab fa-google-plus-g"></i></a></li>
							</ul>
						</div>	</div>

						<div class="col-lg-9 col-md-12 col-sm-12">
							<div class="newsletterInner">
						<div class="form-group">
							<div class="input-group">
								<input class="form-control" onChange={handelInputs} name="email" value={subscriber.email} placeholder="Your Email..." type="email" />
								<span class="input-group-btn">
									<button class="btn btnTheme" onClick={postData} type="button">Subscribe</button>
								</span>
							</div>
						</div>
					</div>
					</div>


			</div>

			</div>



		<div class="footerbottom">
			<div class="row">
				
				
				
				
				
					<div class="row">
					<div class="col-lg-3 col-md-6 col-sm-6 footerRES">
							<div class="footerInner fttitle ftcommonn">
								<h3>Services</h3>
								<ul>   
			<li><Link to="/crypto-payments-for-psps" >Crypto payment for retail POS</Link>  </li>
			<li><Link to="/crypto-payment-for-ecommerce" >Crypto payment for Ecommerce</Link></li>
			<li><Link to="/crypto-payment-by-invoicing" >Crypto payment by invoicing</Link></li>
			<li><Link to="/crypto-payment-for-psps" >Crypto payment for PSPs</Link></li>
			<li>	<Link to="/crypto-payment-for-remittance" >Crypto payment for Remittance</Link></li>
							<li><Link to="/crypto-trading-for-digital-wallet" >Crypto Trading for digital wallet</Link></li>
							</ul>
							</div>
						</div>	

						<div class="col-lg-3 col-md-6 col-sm-6 footerRES">
							<div class="footerInner fttitle ftcommonn">
								<h3>Company</h3>
								<ul>
								<li><Link to="/about-us" >About us</Link></li>
			 <li><Link to="/newsroom" >Newsroom</Link></li>
			 <li><Link to="/terms-and-conditions" >Privacy & terms</Link></li>
			 <li><Link to="/careers" >Careers</Link></li>
			 <li><Link to="/faq" >Faq</Link></li>

								</ul>
							</div>
						</div>	

						<div class="col-lg-3 col-md-6 col-sm-6 footerRES">
							<div class="footerInner fttitle ftcommonn">
								<h3>Resources</h3>
								<ul>
								<li>		<Link to="/crypto-ownership-data" >Crypto Ownership Data</Link></li>
			 <li>		<Link to="/blog" >Blogs</Link></li>
			 <li>	<Link to="/whitepaper" >Whitepaper</Link></li>
			 <li>	<Link to="/contact" >Support</Link></li>
			 <li>	<Link to="/api-documentation" >API Documentation</Link></li>
			 <li>		<Link to="/Exchange_rates" >Exchange rate</Link></li>

								</ul>
							</div>
						</div>






						<div class="col-lg-3 col-md-6 col-sm-6 footerRES">
					<div class="footerInner fttitle">
					<h3>Location</h3>

						<div class="addressFooter">
									<ul>
									<li>
											<span><i class="fas fa-map-marker"></i></span>
											<p> {companyinfodata.body.company_address}</p>
										</li>
										<li>
											<span><i class="fas fa-phone"></i></span>
											<p> {companyinfodata.body.company_phone}</p>
										</li>
										<li>
											<span><i class="fas fa-envelope"></i></span>
											<p>{companyinfodata.body.company_email}</p>
										</li>
									</ul>
								</div>

					</div>
				</div>	






					</div>
				</div>
			</div>
		</div>

	<div class="copy">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12 col-sm-12 copyRES">
					<div class="copyInner">
						<p>&copy; Copyright {companyinfodata.body.company_start}  <Link to="" target="_blank">Cryptocoin</Link>. All rights reserved.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	</div>



    
    </>
    ) }
    export default Footer;