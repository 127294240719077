//import {Link,useNavigate} from 'react-router-dom';
import {useState,useEffect}  from 'react';
import {postdataa} from '../service/Makerequest';
const Logout=()=>{   
  const [user, setUser] = useState({ token: "" }); 
  useEffect(async()=>{
    var token=localStorage.getItem('token');
    setUser({token:token});
    await postdataa('users/logout',user);
    localStorage.clear();
    window.location.href='/';
  })	  
}
export default  Logout;