
import React,{useEffect,useState} from 'react';
import {getpagecontent} from '../service/Makerequest';
import { Dna } from 'react-loader-spinner'
import moment from "moment";
function Transaction() {
  const [invoicedata,setData]=useState({'payload':'','body':'','loader':true});		
	const callpagecontent=async()=>{	
    const tokenString = localStorage.getItem("token");                
    const token = JSON.parse(tokenString);  		
		const data=await getpagecontent('payment/invoice',token);				
		setData({'payload':data.payload,'body':data.payload.data,'loader':false}) 	
	}
	useEffect(()=>{					
		callpagecontent();			
	},[]);
  
  return (
   <>
  <Dna
			visible={invoicedata.loader}
			height="80"
			width="80"
			ariaLabel="dna-loading"
			wrapperStyle={{'margin':'0 50%'}}
			wrapperClass="dna-wrapper"
		/>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="Paymentactivity">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12"><div class="Payment_tittl">Invoice List</div></div>
        </div>
        <div class="row">
          <div class="chart">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <table class="table">
                <thead class="table-dark">
                  <tr>
                    <th>Sr No.</th>                                            
                    <th>Payer Name</th>
                    <th>Payer Email</th>                                            
                    <th>Crypto Amount</th>
                    <th>FIAT Currency</th>            
                    <th>Description</th>
                    <th>Payment Reference</th>
                    <th>Status</th>
                    <th>Date</th>
                  </tr>
                </thead>
                {invoicedata.body.length > 0 && ( 
                <tbody>           
                  {invoicedata.body.map((invoice,i) => (
                    <tr>                        
                      <td>{i+1}</td>                                            
                      <td>{invoice.payer_name}</td>
                      <td>{invoice.payer_email}</td>                                            
                      <td>{invoice.crypto_receive_amount}({invoice.crypto_currency})</td>
                      <td>{invoice.payment_receive_amount} ({invoice.payment_currency})</td>     
                      <td>{invoice.payer_description}</td>
                      <td>{invoice.payment_reference}</td>
                      <td>{invoice.status}</td>
                      <td>{moment(invoice.created_at).format("MMMM Do, YYYY HH:MM:SS")}{" "}</td>
                    </tr>
                  ))}
                </tbody>
                )}
              </table>
            </div>			
		      </div>
	      </div>
      </div> 
    </div>  
  </div>
</>
  );
}

export default Transaction;
