import React  from 'react';
import { Link} from 'react-router-dom';

const Error = () => {
	
	return(
	
	<>
	

  

  
        <section className="error_messageouter">
	


		   	<div className="errrorwrap">
			
			   
			   <div className="error_message">   <figure><img className="img-fluid" src="/images/error.jpg" alt="" /></figure>




			   <div className="error_messageinn">

	    <h6> This page doesn't exist </h6>	
			</div>	

			<div className="errrorback"><Link to="/" className='btn btnTheme btnReg'>Back to Home</Link></div>
			</div>
			
			<div className="erroranimtion"><img src="/images/sf_svip.webp" class="img-fluid flicker" alt="" /></div>
			
				</div>
				</section>
	
	</>
	);
	
	
}
	
export default Error	
	