
import React  from 'react';




function Exchange_rates (){

return(
<>




<section class="exchangeRatesMain">
	<div class="container"> 
		<div class="row">
			<div class="col-lg-8 offset-lg-2 col-md-12 col-sm-12">
				<div class="headingOther">
					<h2><span>Exchange </span>rates</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 col-md-12 col-sm-12">
				<div class="exchangeRatesInner">
					<div class="table-responsive">
						<table class="table table-striped">
							<thead>
								<tr>
									<th>#</th>
									<th>for Bitcoin</th>
									<th>for Ethereum</th>
									<th>for for Tether (USDT)</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>US Dollar</td>
									<td>21,420.70 USD</td>
									<td>1,66451.79 USD</td>
									<td>47.00 USD</td>
								</tr>
								<tr>
									<td>Danish Krone</td>
									<td>21,420.70 USD</td>
									<td>7,6451.9 USD</td>
									<td>18.00 USD</td>
								</tr>
								<tr>
									<td>Egyptian Pound</td>
									<td>21,420.70 USD</td>
									<td>76,661.79 USD</td>
									<td>65.00 USD</td>
								</tr>
								<tr>
									<td>British Pound</td>
									<td>11,120.70 USD</td>
									<td>32,112.79 USD</td>
									<td>38.070 USD</td>
								</tr>
								<tr>
									<td>Australian Dollar</td>
									<td>21,420.70 USD</td>
									<td>14,661.79 USD</td>
									<td>134.00 USD</td>
								</tr>
								<tr>
									<td>Bangladeshi Taka</td>
									<td>31,10.70 USD</td>
									<td>24,21.79 USD</td>
									<td>53.00 USD</td>
								</tr>
								<tr>
									<td>Swiss Franc</td>
									<td>21,420.70 USD</td>
									<td>76,661.79 USD</td>
									<td>14.00 USD</td>
								</tr>
								<tr>
									<td>US Dollar</td>
									<td>21,420.70 USD</td>
									<td>1,66451.79 USD</td>
									<td>47.00 USD</td>
								</tr>
								<tr>
									<td>Danish Krone</td>
									<td>21,420.70 USD</td>
									<td>7,6451.9 USD</td>
									<td>18.00 USD</td>
								</tr>
								<tr>
									<td>Egyptian Pound</td>
									<td>21,420.70 USD</td>
									<td>76,661.79 USD</td>
									<td>65.00 USD</td>
								</tr>
								<tr>
									<td>British Pound</td>
									<td>11,120.70 USD</td>
									<td>32,112.79 USD</td>
									<td>38.070 USD</td>
								</tr>
								<tr>
									<td>Euro</td>
									<td>21,420.70 USD</td>
									<td>54,661.79 USD</td>
									<td>45.00 USD</td>
								</tr>
								<tr>
									<td>Colombian Peso</td>
									<td>21,420.70 USD</td>
									<td>13,661.79 USD</td>
									<td>67.00 USD</td>
								</tr>
								<tr>
									<td>Bahraini Dinar</td>
									<td>21,420.70 USD</td>
									<td>76,81.67 USD</td>
									<td>61.45 USD</td>
								</tr>
								<tr>
									<td>Bangladeshi Taka</td>
									<td>31,10.70 USD</td>
									<td>24,21.79 USD</td>
									<td>53.00 USD</td>
								</tr>
								<tr>
									<td>Swiss Franc</td>
									<td>21,420.70 USD</td>
									<td>76,661.79 USD</td>
									<td>14.00 USD</td>
								</tr>
								<tr>
									<td>US Dollar</td>
									<td>21,420.70 USD</td>
									<td>1,66451.79 USD</td>
									<td>47.00 USD</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</section> 



</>
)

}
export default  Exchange_rates;